import {
  IonPage,
  IonContent,
  IonIcon,
  IonLabel,
  useIonAlert,
  IonHeader,
  IonToolbar,
  IonButton,
  IonList,
  IonListHeader,
  IonSkeletonText,
  IonItem,
  IonThumbnail,
  IonCard,
  IonCardContent,
  IonText,
  IonInput,
  IonImg,
  IonFab,
  IonFabButton,
  IonCardHeader,
  IonBadge,
  IonGrid,
  IonRow,
  IonCol,
  IonFooter,
  useIonToast,
  useIonModal
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { RestApi } from "../../services/Api";
import { addCircleOutline, arrowBackOutline, refreshCircleOutline, removeCircleOutline, sadOutline, trashBin } from "ionicons/icons";
import { useTranslation } from "react-i18next";
import { So } from "../../models/Modal";
import { Discount, loadList } from "../../models/Data";
import SelectOption from "../input/SelectOption";
import InputDate from "../input/Date";
import { getNameDate, Rupiah } from "../../services/Library";
import { useDispatch, useSelector } from "react-redux";
import { CartAction } from "../../data/state/CartReducer";
import Checkout from "./Checkout";
import "./Form.scss";

const useConstructor = (callBack = () => {}) => {
  const [hasBeenCalled, setHasBeenCalled] = useState(false);
  if (hasBeenCalled) return;
  callBack();
  setHasBeenCalled(true);
};

const Form: React.FC<So> = (props) => {
  const { t } = useTranslation(["general"]);
  const [alert] = useIonAlert();
  const dispatch = useDispatch();
  const [presentToast] = useIonToast();
  const user = useSelector((state: any) => state.user.value);
  const cart = useSelector((state: any) => state.cart.value);
  const [showModalDate, setShowModalDate] = useState<boolean>(false);
  const [showModalDateExpected, setShowModalDateExpected] = useState<boolean>(false);
  const [soDateExpected, setSoDateExpected] = useState<any>(new Date().toISOString().slice(0, 10));
  const [soDate, setSoDate] = useState<any>(new Date().toISOString().slice(0, 10));
  const [customer, setCustomer] = useState<any>([]);
  const [customerAddress, setCustomerAddress] = useState<any>([]);
  const [salesman, setSalesman] = useState<any>([]);
  const [term, setTerm] = useState<any>([]);
  const [item, setItem] = useState<any>([]);
  const [soFormId, setSoFormId] = useState<any>("");
  const [disc1, setDisc1] = useState<any>([]);  
  const [disc3, setDisc3] = useState<any>([]);
  const [soId, setSoId] = useState<string>("");
  const [visitId, setVisitId] = useState<string>("");
  const [note, setNote] = useState<string>("");
  const [loaded, setLoaded] = useState<boolean>(false);
  const [showModalSelectOptionCustomerId, setShowModalSelectOptionCustomerId] = useState<boolean>(false);
  const [showModalSelectOptionSalesman, setShowModalSelectOptionSalesman] = useState<boolean>(false);
  const [showModalSelectOptionTerm, setShowModalSelectOptionTerm] = useState<boolean>(false);
  const [showModalSelectOptionItem, setShowModalSelectOptionItem] = useState<boolean>(false);
  const [showModalSelectOptionAddress, setShowModalSelectOptionAddress] = useState<boolean>(false);
  const [setting, setSetting] = useState<any>([]);
  const [totalAmount, setTotalAmount] = useState<any>(0);
  const [totalQty, setTotalQty] = useState<any>(0);
  const [soBaseAmount, setSoBaseAmount] = useState<any>(0);
  const [soTaxRateAmount, setSoTaxRateAmount] = useState<any>(0);
  const [selectCustomer, setSelectCustomer] = useState<any>({
    customer_id: "-",
    customer_name: "-",
    customer_address: [],
  });
  const [selectSalesman, setSelectSalesman] = useState<any>({
    salesman_id: "-",
    salesman_name: "-"
  });
  const [selectTerm, setSelectTerm] = useState<any>({
    term_id: 1,
    term_name: "-",
    term_days: 0
  });
    const [selectCustomerAddress, setSelectCustomerAddress] = useState<any>({
      customer_address_id: "-",
      customer_address_name: "-",
      customer_address: "-",
    });

  const massageAlert = async (status: string, body: string) => {
    alert({
      header: t(status),
      message: body,
      cssClass: `header-alert-${status}`,
      buttons: [
        { text: "Ya", cssClass: "alert-button-confirm", role: "confirm" },
      ],
    });
  };

  const handleDismissCheckout = (status:boolean = false) => {
    if (status) {
      props.onDismiss();
    }
    dismissCheckout();
  };

  const [presentCheckout, dismissCheckout] = useIonModal(Checkout, {
    so: {
      so_id: soId,
      so_form_id: soFormId,
      so_date: soDate,
      so_expected_date: soDateExpected,
      so_salesman_id: selectSalesman.salesman_id,
      so_customer_id: selectCustomer.customer_id,
      so_customer_address_id: selectCustomerAddress.customer_address_id,
      so_customer_name: selectCustomer.customer_name,
      so_branch_id: selectCustomer.branch_id,
      so_customer_branch: selectCustomer.branch_name,
      so_term_id: selectTerm.term_id,
      so_total_quantity: totalQty,
      so_total_amount: totalAmount,
      so_subtotal_amount: totalAmount,
      so_tax_rounded_amount: Number(soTaxRateAmount.toFixed(2)),
      so_tax_rate: setting[1]?.settings_value ?? 11 / 100,
      so_base_amount: Number(soBaseAmount.toFixed(2)),
      so_tax_amount: parseFloat(
        (
          soBaseAmount * (Number(setting[1]?.settings_value / 100) ?? 11 / 100)
        ).toFixed(2)
      ),
      so_remark: note,
      visit_id: visitId,
      sodet: cart,
    },
    type: props.type,
    customer: selectCustomer,
    onDismiss: handleDismissCheckout,
  });

  const handleModalDateClose = (inputDate: any) => {
    if (inputDate) {
      const currentDate = new Date().toLocaleDateString();
      const inputDateTime = new Date(inputDate).toLocaleDateString();
      // Check if input date is greater than or equal to current date
      if (inputDateTime >= currentDate || inputDateTime === currentDate) {
        // Set SO date
        setSoDate(inputDate);
        // Check if SO expected date is less than or equal to input date
        if (new Date(soDateExpected).toLocaleDateString() <= inputDateTime) {
          setSoDateExpected(inputDate);
        }
      } else {
        // Input date is a backdate
        presentToast({
          message: `Tanggal Buat Tidak Bisa Back Date!!!`,
          duration: 1500,
          color: "danger",
          position: "middle",
          icon: sadOutline,
        });
      }
    }
    setShowModalDate(false);
  };

  const handleModalDateExpectedClose = (inputDateExpected: any) => {
    if (inputDateExpected) {
      if (new Date(inputDateExpected).toLocaleDateString() >= new Date(soDate).toLocaleDateString()) {
        setSoDateExpected(inputDateExpected);
      } else {
        presentToast({
          message: `Tgl. Ekspetasi Tidak Boleh Kurang Dari Tanggal Buat`,
          duration: 1500,
          color: "danger",
          position: "middle",
          icon: sadOutline,
        });
      }
    }
    setShowModalDateExpected(false);
  };

  const getForm = async (page: string) => {
    await RestApi(`${page}`, "get", {}, "application/json")
      .then(async  (res: any) => {
        if (res.data.code === 200) {
          setTerm(res.data.data.term);
          setDisc1(res.data.data.disc1);
          setDisc3(res.data.data.disc3);
          setSetting(res.data.data.setting);
          setSalesman(res.data.data.salesman);
          setItem(res.data.data.item);
          if (props.type === "create") {
            let getCustomer:any = {};
            setCustomer(res.data.data.customer);
            setSoId(res.data.data.autonumber);
            setSoFormId(`GSS-${res.data.data.autonumber}`);
            if (res.data.data.salesman.length === 1) {
              setSelectSalesman(
                res.data.data.salesman.find(
                  (val: any) => val.salesman_id === user.user_salesman_id
                )
              );
            }
            if (props.idVisit !== "") {
              setVisitId(props.idVisit);
              getCustomer = res.data.data.customer.find((val: any) => val.customer_id === props.idCustomer );
              if (getCustomer) {
                setSelectCustomer(getCustomer);
              }else{
                massageAlert("error", `Salesman area tersebut bukan termasuk di area customer!!!`);
                props.onDismiss();
              }
              setCustomerAddress(getCustomer.customer_address);
            }
            let term = res.data.data.term[res.data.data.setting[2]?.settings_value-1]??res.data.data.term[0];
            setSelectTerm(term);
            cart.map(async (item: any) => {
              let setItem = getDiscItem(
                item,
                getCustomer,
                term,
                res.data.data.setting
              );
              dispatch(CartAction.addItem(setItem));
            });
          } else {
            setSoId(res.data.data.so.so_id);
            setSoFormId(res.data.data.so.so_form_id);
            setSoDate(res.data.data.so.so_date);
            setSoDateExpected(res.data.data.so.so_expected_date);
            setNote(res.data.data.so.so_remark);
            setCustomerAddress(res.data.data.so.customer.customer_address);
            setCustomer([
              {
                customer_id: res.data.data.so.customer.customer_id,
                customer_name: res.data.data.so.customer.customer_name,
                customer_has_disc1:res.data.data.so.customer.customer_has_disc1,
                customer_has_disc2: res.data.data.so.customer.customer_has_disc2,
                customer_has_disc3: res.data.data.so.customer.customer_has_disc3,
                customer_price_list:res.data.data.so.customer.customer_price_list,
                area_id: res.data.data.so.customer.subarea_area_id,
                branch_id: res.data.data.so.branch_id,
                branch_name: res.data.data.so.branch_name,
                disc2: res.data.data.so.customer.disc2,
              },
            ]);
            setSelectCustomer({
              customer_id: res.data.data.so.customer.customer_id,
              customer_name: res.data.data.so.customer.customer_name,
              customer_has_disc1: res.data.data.so.customer.customer_has_disc1,
              customer_has_disc2: res.data.data.so.customer.customer_has_disc2,
              customer_has_disc3: res.data.data.so.customer.customer_has_disc3,
              customer_price_list: res.data.data.so.customer.customer_price_list,
              area_id: res.data.data.so.customer.subarea_area_id,
              branch_id: res.data.data.so.branch_id,
              branch_name: res.data.data.so.branch_name,
              disc2: res.data.data.so.customer.disc2,
            });
            setSelectSalesman({
              salesman_id: res.data.data.so.salesman_id,
              salesman_name: res.data.data.so.salesman_name,
              salesman_area: [],
            });
            setSelectCustomerAddress({
              customer_address_id: res.data.data.so.customer_address_id,
              customer_address_name:
                res.data.data.so.customer_address.customer_address_name,
              customer_address:
                res.data.data.so.customer_address.customer_address,
            });
            setSelectTerm({
              term_id: res.data.data.so.term_id,
              term_name: res.data.data.so.term_name,
              term_days: res.data.data.so.term_days
            });
            res.data.data.so.sodet.forEach((item:any) => {
              dispatch(
                CartAction.addItem({
                  item_id: item.sodet_item_id,
                  item_name: item.sodet_item_name,
                  item_unit: item.sodet_item_unit,
                  item_base_price: Number(item.sodet_item_base_price),
                  item_category_id: item.item_category_id,
                  item_price: Number(item.sodet_item_price),
                  item_disc_cash_amount: Number(item.sodet_disc_cash_amount),
                  item_disc_rate: Number(item.sodet_disc_rate),
                  item_disc_rate_amount: Number(item.sodet_disc_rate_amount),
                  item_disc_total: Number(item.sodet_disc_total),
                  item_disc_text: item.sodet_disc_text,
                  item_outstanding: Number(item.sodet_item_outstanding),
                  item_subtotal: Number(item.sodet_subtotal),
                  item_quantity: Number(item.sodet_item_quantity),
                  item_subtotal_base: Number(item.sodet_subtotal_base),
                  item_subtotal_tax: Number(item.sodet_subtotal_tax),
                })
              );
            });
          }
          setLoaded(true);
        } else {
          massageAlert("error", res.data.message);
          props.onDismiss();
        }
      })
      .catch((err: any) => {
        massageAlert("error", err.message);
        props.onDismiss();
      });
  };

  const customerSelectionChanged = async (idCustomer: string[]) => {
    let getCustomer = customer.find(
      (val: any) => val.customer_id === idCustomer
    );
    setSelectCustomer(getCustomer);
    setCustomerAddress(getCustomer.customer_address);
    setSelectCustomerAddress({
      customer_address_id: "-",
      customer_address_name: "-",
      customer_address: "-",
    });
    cart.map(async (item: any) => {
      let setItem = getDiscItem(item, getCustomer, selectTerm, setting);
      dispatch(CartAction.addItem(setItem));
    });
  };

  const salesmanSelectionChanged = (idSalesman: string[]) => {
    let getSalesman = salesman.find((val: any) => val.salesman_id === idSalesman);
    if (getSalesman.salesman_area.length) {
      let getCustomer = getSalesman.salesman_area.find((val: any) => val.salesman_area_area_id === selectCustomer.area_id);
      if (getCustomer) {
        setSelectSalesman(getSalesman);
      }else{
        presentToast({
          message: `Salesman area tersebut bukan termasuk di area customer!!!`,
          duration: 1500,
          color: "danger",
          position: "middle",
          icon: sadOutline,
        });
      }
    }else{
      setSelectSalesman(getSalesman);
    }
  };

  const termSelectionChanged = async (idTerm: string[]) => {
    let getTerm = term.find((val: any) => val.term_id === idTerm);
    setSelectTerm(getTerm);
    cart.map(async (item: any) => {
      let setItem = getDiscItem(item, selectCustomer, getTerm, setting);
      dispatch(CartAction.addItem(setItem));
    });
  };

  const itemSelectionChanged = (idItem: string[]) => {
    let getItem = item.find((val: any) => val.item_id === idItem);
    let setItem = getDiscItem(getItem, selectCustomer, selectTerm, setting);
    dispatch(CartAction.addItem(setItem));
  };

  const addressSelectionChanged = (idAddress: string[]) => {
    setSelectCustomerAddress(customerAddress.find((val: any) => val.customer_address_id === idAddress));
  };

  const getDiscItem = (item:any,customer:any,term:any, selectSetting:any) => {
    let discText = '';
    if (customer?.customer_has_disc1) {
      //search disc1
      let getDisc1 = disc1.find((val: any) => val.disc1_category_id === item.item_category_id);
      if (getDisc1) {
        discText = getDisc1.disc1_disc_text.trim();
      }
    }

    if (customer?.customer_has_disc2) {
      //search disc2
      let getDisc2 = customer.disc2.find((val: any) => val.disc2_category_id === item.item_category_id);
      if (getDisc2) {
        if (discText.length === 0) {
          discText = getDisc2.disc2_disc_text.trim();
        } else {
          if (getDisc2.disc2_disc_text.length !== 0) {
            discText = discText + "+" + getDisc2.disc2_disc_text.trim();
          }
        }
      }
    }

    if (customer?.customer_has_disc3) {
      //search disc3
      let getDisc3 = disc3.find((val: any) => val.disc3_term_id === term.term_id);
      if (getDisc3) {
        if (discText.length === 0) {
          discText = getDisc3.disc3_disc_text.trim();
        } else {
          if (getDisc3.disc3_disc_text.length !== 0) {
            discText = discText + "+" + getDisc3.disc3_disc_text.trim();
          }
        }
      }
    }
    let setItem = {
      item_id: item.item_id,
      item_name: item.item_name,
      item_unit: item.item_unit,
      item_base_price: Number(item[`item_price_list${customer.customer_price_list??1}`]??item.item_base_price),
      item_category_id: item.item_category_id,
      item_outstanding: item.item_outstanding ?? 0,
      item_price: item.item_price ?? Number(item[`item_price_list${customer.customer_price_list??1}`]??item.item_price),
      item_disc_cash_amount: item.item_disc_cash_amount ?? 0,
      item_disc_rate: item.item_disc_rate ?? 0,
      item_disc_rate_amount: item.item_disc_rate_amount ?? 0,
      item_disc_total: item.item_disc_total ?? 0,
      item_disc_text: discText,
      item_price_list1: item.item_price_list1,
      item_price_list2: item.item_price_list2,
      item_price_list3: item.item_price_list3,
      item_subtotal: item.item_subtotal ?? 0,
      item_quantity: item.item_quantity ?? 1,
      item_subtotal_base : 0,
      item_subtotal_tax: 0,
    };
    let discCount = countDiscount(setItem.item_base_price, discText);
    if (discCount) {
      setItem.item_disc_rate = discCount.total_disc;
      setItem.item_disc_rate_amount = discCount.total_amount;
      setItem.item_disc_total = setItem.item_disc_rate_amount + setItem.item_disc_cash_amount
      if (setItem.item_disc_total >= setItem.item_base_price) {
        setItem.item_disc_total = setItem.item_base_price - 1;
        setItem.item_disc_cash_amount = (setItem.item_disc_total - discCount.total_amount)
      }
      setItem.item_price = setItem.item_base_price - setItem.item_disc_total;
      if (setItem.item_price < 0) {
        setItem.item_price = 0;
      }
    }
    let taxRate = Number(selectSetting[1].settings_value ?? 11) / 100;
    setItem.item_subtotal = setItem.item_price * setItem.item_quantity;
    setItem.item_subtotal_base = setItem.item_subtotal / (1 + taxRate);
    setItem.item_subtotal_tax = setItem.item_subtotal - setItem.item_subtotal_base;
    return setItem;
  }

  const countDiscount = (discvalue: number | null, strdiscount: string | null): Discount | false => {
    if (discvalue === null || strdiscount === null) {
        return false;
    }
    const regex = /[^0-9,.+]/g;
    strdiscount = strdiscount.replace(regex, "");
    const discarray = strdiscount.split('+');
    const disc: Discount = {};
    let amount = discvalue;
    let smount = 0;
    let rate = 1;
    let srate = 0;
    let arate = 0;
    let trate = 0;
    let tamount = 0;
    discarray.forEach((value, index) => {
        const key = index + 1;
        disc['disc' + key] = +value;
        if (disc['disc' + key] > 100) {
            value = '100';
        }
        disc['rate' + key] = parseFloat((+value / 100).toFixed(4));
        arate = parseFloat((rate * disc['rate' + key]).toFixed(4));
        srate = rate - arate;
        rate = srate;
        trate += arate;

        disc['amount' + key] = parseFloat((amount * disc['rate' + key]).toFixed(2));
        smount = amount - disc['amount' + key];
        amount = smount;
        tamount += disc['amount' + key];
    });
    disc['total_disc'] = trate * 100;
    disc['total_rate'] = trate;
    disc['total_amount'] = tamount;
    return disc;
  }

  useConstructor(() => {
    if (props.type === "create") {
      getForm(`so/create`);
    } else {
      getForm(`so/${props.idSo}/edit`);
    }
  });
  
  useEffect(() => {
    if (cart.length) {
      let qty = 0;
      let amount = 0;
      let subtotalBase = 0;
      let subtotalTax = 0;
      cart.forEach((item: any) => {
        qty += item.item_quantity;
        amount += item.item_subtotal;
        subtotalBase += item.item_subtotal_base;
        subtotalTax += item.item_subtotal_tax;
      });
      setTotalAmount(amount);
      setTotalQty(qty);
      setSoBaseAmount(subtotalBase);
      setSoTaxRateAmount(subtotalTax);
    } else {
      setTotalAmount(0);
      setTotalQty(0);
    }
  }, [
    cart,
    selectCustomer,
    selectCustomerAddress,
    selectTerm,
    selectSalesman,
    customerAddress,
    term,
    salesman,
    soDate,
    soDateExpected,
    soId,
    soFormId,
  ]);
  return (
    <IonPage>
      <IonHeader className="toolbar-form">
        <IonToolbar className="ion-no-padding">
          <IonButton
            color="light"
            slot="start"
            fill="clear"
            onClick={() => {
              if (props.type === "edit") {
                dispatch(CartAction.clearItem(true));
              }
              props.onDismiss();
            }}
          >
            <IonIcon color="light" slot="start" src={arrowBackOutline} />
          </IonButton>
          <IonLabel color="light" className="ion-text-center ion-no-padding">
            <h2>{t(props.type) + " " + t("module.so")}</h2>
          </IonLabel>
          {props.type === "create" ? (
            <IonButton
              fill="clear"
              slot="end"
              onClick={() => {
                getForm(`so/create`);
              }}
            >
              <IonIcon color="light" size="medium" src={refreshCircleOutline} />
            </IonButton>
          ) : (
            <IonButton fill="clear" slot="end"></IonButton>
          )}
        </IonToolbar>
      </IonHeader>
      {loaded ? (
        <>
          <IonContent className="form-content">
            <IonCard>
              <IonGrid>
                <IonRow>
                  <IonCol size="6">
                    <IonItem className="ion-no-padding">
                      <IonInput
                        labelPlacement="floating"
                        name="so_customer_id"
                        label={`${t("name")} ${t("module.customer")}*`}
                        className="ion-text-end ion-no-padding"
                        readonly={true}
                        value={`${selectCustomer.customer_name} (${selectCustomer.customer_id})`}
                        onClick={() => {
                          if (props.type === "create" && !visitId) {
                            setShowModalSelectOptionCustomerId(true);
                          }
                        }}
                      ></IonInput>
                    </IonItem>
                  </IonCol>
                  <IonCol size="6">
                    <IonItem className="ion-no-padding">
                      <IonInput
                        labelPlacement="floating"
                        placeholder={t("address")}
                        name="so_customer_address_id"
                        label={t("address")}
                        className="ion-text-end"
                        onClick={() => {
                          if (selectCustomer.customer_id === "-") {
                            presentToast({
                              message: `${t("required")} ${t(
                                "module.customer"
                              )}`,
                              duration: 1500,
                              color: "danger",
                              position: "middle",
                              icon: sadOutline,
                            });
                          } else {
                            setShowModalSelectOptionAddress(true);
                          }
                        }}
                        value={`${selectCustomerAddress.customer_address_name} = ${selectCustomerAddress.customer_address}`}
                      ></IonInput>
                    </IonItem>
                  </IonCol>
                  <IonCol size="6">
                    <IonItem className="ion-no-padding">
                      <IonInput
                        labelPlacement="floating"
                        name="so_id"
                        label="ID"
                        className="ion-text-end"
                        readonly={true}
                        value={soId}
                      ></IonInput>
                    </IonItem>
                  </IonCol>
                  <IonCol size="6">
                    <IonItem className="ion-no-padding">
                      <IonInput
                        labelPlacement="floating"
                        placeholder={t("order-number")}
                        name="so_form_id"
                        label={t("order-number")}
                        className="ion-text-end"
                        readonly={true}
                        value={soFormId}
                      ></IonInput>
                    </IonItem>
                  </IonCol>
                  <IonCol size="6">
                    <IonItem className="ion-no-padding">
                      <IonInput
                        labelPlacement="floating"
                        placeholder={`Tgl. Pesanan`}
                        name="so_date"
                        label="Tgl. Pesanan"
                        className="ion-text-end"
                        readonly={true}
                        onClick={() => {
                          if (!visitId) {
                            setShowModalDate(true);
                          }
                        }}
                        value={getNameDate(soDate)}
                      ></IonInput>
                    </IonItem>
                  </IonCol>
                  <IonCol size="6">
                    <IonItem className="ion-no-padding">
                      <IonInput
                        labelPlacement="floating"
                        placeholder={`Tgl. Ekspetasi`}
                        name="so_expected_date"
                        label="Tgl. Ekspetasi"
                        className="ion-text-end"
                        readonly={true}
                        onClick={() => setShowModalDateExpected(true)}
                        value={getNameDate(soDateExpected)}
                      ></IonInput>
                    </IonItem>
                  </IonCol>
                  <IonCol size="6">
                    <IonItem className="ion-no-padding">
                      <IonInput
                        labelPlacement="floating"
                        placeholder={t("salesman")}
                        name="so_salesman"
                        label={t("salesman")}
                        className="ion-text-end"
                        onClick={() => {
                          if (selectCustomer.customer_id === "-") {
                            presentToast({
                              message: `${t("required")} ${t(
                                "module.customer"
                              )}`,
                              duration: 1500,
                              color: "danger",
                              position: "middle",
                              icon: sadOutline,
                            });
                          } else {
                            setShowModalSelectOptionSalesman(true);
                          }
                        }}
                        value={`${selectSalesman.salesman_name} (${selectSalesman.salesman_id})`}
                      ></IonInput>
                    </IonItem>
                  </IonCol>
                  <IonCol size="6">
                    {user?.user_role_id !== "SALES" ? (
                      <IonItem className="ion-no-padding">
                        <IonInput
                          labelPlacement="floating"
                          name="so_term"
                          label={`${t("term")}`}
                          className="ion-text-end ion-no-padding"
                          readonly={true}
                          value={`${selectTerm.term_name} TOP: ${
                            selectTerm.term_days
                          } ${t("day")}`}
                          onClick={() => setShowModalSelectOptionTerm(true)}
                        ></IonInput>
                      </IonItem>
                    ) : (
                      ""
                    )}
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCard>
            <IonCard>
              <IonCardHeader>
                <IonItem className="ion-no-padding">
                  <IonText>{`${t("list")} ${t("item")}`}</IonText>
                </IonItem>
              </IonCardHeader>
              <IonCardContent className="ion-no-padding ion-no-margin">
                {cart.length ? (
                  <>
                    {cart.map((item: any, key: number) => (
                      <IonCard style={{ marginTop: "5px" }} key={key}>
                        <IonRow className="ion-no-padding ion-no-margin">
                          <IonCol
                            size="12"
                            className="ion-no-padding ion-no-margin"
                          >
                            <IonItem className="ion-no-padding ion-no-margin">
                              <IonRow className="ion-no-padding ion-no-margin">
                                <IonCol
                                  size="8"
                                  className="ion-no-padding ion-no-margin"
                                >
                                  <IonText
                                    className="ion-no-padding ion-no-margin ion-text-nowrap"
                                    color={"dark"}
                                  >
                                    <p style={{ paddingLeft: "5px" }}>
                                      <strong>
                                        {item.item_name} ({item.item_id})
                                      </strong>
                                    </p>
                                    <p style={{ paddingLeft: "5px" }}>
                                      <strong>
                                        {`${Rupiah(
                                          Number(item.item_base_price)
                                        )} `}
                                      </strong>
                                      {item.item_disc_text !== "" && (
                                        <IonBadge color="danger">
                                          <strong>
                                            {item.item_disc_text} %
                                          </strong>
                                        </IonBadge>
                                      )}
                                    </p>
                                  </IonText>
                                </IonCol>
                                <IonCol
                                  size="4"
                                  className="ion-no-padding ion-no-margin ion-float-end"
                                >
                                  {user?.user_role_id !== "SALES" ? (
                                    <IonInput
                                      className="ion-no-padding ion-no-margin ion-text-end"
                                      placeholder={`${t("add")} ${t(
                                        "discount"
                                      )}`}
                                      value={
                                        item.item_disc_cash_amount === 0
                                          ? ""
                                          : item.item_disc_cash_amount
                                      }
                                      type={"number"}
                                      onIonInput={(e) => {
                                        let itemSubtotal =
                                          item.item_price * item.item_quantity;
                                        if (Number(e.detail.value) === 0) {
                                          presentToast({
                                            message: ` ${t("required")} ${t(
                                              "add"
                                            )} ${t("discount")}`,
                                            duration: 1500,
                                            color: "danger",
                                            position: "middle",
                                            icon: sadOutline,
                                          });
                                          let getItem = { ...item };
                                          getItem.item_disc_cash_amount = 0;
                                          let setItem = getDiscItem(
                                            getItem,
                                            selectCustomer,
                                            selectTerm,
                                            setting
                                          );
                                          dispatch(
                                            CartAction.changeItem(setItem)
                                          );
                                        } else if (
                                          itemSubtotal <= 0 ||
                                          itemSubtotal === 0
                                        ) {
                                          presentToast({
                                            message: `${t("add")} ${t(
                                              "discount"
                                            )} Lebih Dari Subtotal `,
                                            duration: 1500,
                                            color: "danger",
                                            position: "middle",
                                            icon: sadOutline,
                                          });
                                          let getItem = { ...item };
                                          getItem.item_disc_cash_amount = 0;
                                          let setItem = getDiscItem(
                                            getItem,
                                            selectCustomer,
                                            selectTerm,
                                            setting
                                          );
                                          dispatch(
                                            CartAction.changeItem(setItem)
                                          );
                                        } else {
                                          let getItem = { ...item };
                                          getItem.item_disc_cash_amount =
                                            Number(e.detail.value);
                                          let setItem = getDiscItem(
                                            getItem,
                                            selectCustomer,
                                            selectTerm,
                                            setting
                                          );
                                          dispatch(
                                            CartAction.changeItem(setItem)
                                          );
                                        }
                                      }}
                                      onIonChange={(e) => {
                                        let itemSubtotal =
                                          item.item_price * item.item_quantity;
                                        if (Number(e.detail.value) === 0) {
                                          presentToast({
                                            message: ` ${t("required")} ${t(
                                              "add"
                                            )} ${t("discount")}`,
                                            duration: 1500,
                                            color: "danger",
                                            position: "middle",
                                            icon: sadOutline,
                                          });
                                          let getItem = { ...item };
                                          getItem.item_disc_cash_amount = 0;
                                          let setItem = getDiscItem(
                                            getItem,
                                            selectCustomer,
                                            selectTerm,
                                            setting
                                          );
                                          dispatch(
                                            CartAction.changeItem(setItem)
                                          );
                                        } else if (
                                          itemSubtotal <= 0 ||
                                          itemSubtotal === 0
                                        ) {
                                          presentToast({
                                            message: `${t("add")} ${t(
                                              "discount"
                                            )} Lebih Dari Subtotal `,
                                            duration: 1500,
                                            color: "danger",
                                            position: "middle",
                                            icon: sadOutline,
                                          });
                                          let getItem = { ...item };
                                          getItem.item_disc_cash_amount = 0;
                                          let setItem = getDiscItem(
                                            getItem,
                                            selectCustomer,
                                            selectTerm,
                                            setting
                                          );
                                          dispatch(
                                            CartAction.changeItem(setItem)
                                          );
                                        } else {
                                          let getItem = { ...item };
                                          getItem.item_disc_cash_amount =
                                            Number(e.detail.value);
                                          let setItem = getDiscItem(
                                            getItem,
                                            selectCustomer,
                                            selectTerm,
                                            setting
                                          );
                                          dispatch(
                                            CartAction.changeItem(setItem)
                                          );
                                        }
                                      }}
                                    ></IonInput>
                                  ) : (
                                    ""
                                  )}
                                </IonCol>
                              </IonRow>
                            </IonItem>
                          </IonCol>
                          <IonCol
                            size="7"
                            className="ion-no-padding ion-no-margin"
                          >
                            <IonItem
                              className="ion-no-padding ion-no-margin"
                              lines="none"
                            >
                              <IonText
                                className="ion-no-padding ion-no-margin"
                                color={"dark"}
                                style={{
                                  margin: "3px",
                                }}
                              >
                                <span>
                                  {t("Subtotal")} :{" "}
                                  <strong>{Rupiah(item.item_subtotal)}</strong>
                                </span>
                              </IonText>
                              <IonIcon
                                color="danger"
                                className="ion-float-end ion-no-padding ion-no-margin"
                                size="small"
                                slot="end"
                                src={trashBin}
                                onClick={async () => {
                                  dispatch(CartAction.removeItem(item.item_id));
                                }}
                              ></IonIcon>
                            </IonItem>
                          </IonCol>
                          <IonCol
                            size="5"
                            className="ion-no-padding ion-no-margin"
                          >
                            <IonItem
                              className="ion-no-padding ion-no-margin"
                              lines="none"
                            >
                              <IonButton
                                fill="clear"
                                color="light"
                                className="ion-no-padding ion-no-margin ion-float-end"
                                disabled={
                                  item.item_quantity === 1 ? true : false
                                }
                                onClick={async () => {
                                  if (item.item_quantity > 1) {
                                    let getItem = { ...item };
                                    getItem.item_quantity =
                                      getItem.item_quantity - 1;
                                    let setItem = getDiscItem(
                                      getItem,
                                      selectCustomer,
                                      selectTerm,
                                      setting
                                    );
                                    dispatch(CartAction.changeItem(setItem));
                                  }
                                }}
                              >
                                <IonIcon
                                  src={removeCircleOutline}
                                  color={"danger"}
                                  size="large"
                                ></IonIcon>
                              </IonButton>
                              <IonInput
                                className="ion-no-padding ion-no-margin ion-text-center"
                                value={item.item_quantity}
                                type={"number"}
                                min={1}
                                onIonInput={(e) => {
                                  if (e.detail.value === "0") {
                                    presentToast({
                                      message: t("required"),
                                      duration: 1500,
                                      color: "danger",
                                      position: "middle",
                                      icon: sadOutline,
                                    });
                                  } else {
                                    let getItem = { ...item };
                                    getItem.item_quantity = Number(
                                      e.detail.value
                                    );
                                    let setItem = getDiscItem(
                                      getItem,
                                      selectCustomer,
                                      selectTerm,
                                      setting
                                    );
                                    dispatch(CartAction.changeItem(setItem));
                                  }
                                }}
                                onIonChange={(e) => {
                                  if (e.detail.value === "0") {
                                    presentToast({
                                      message: t("required"),
                                      duration: 1500,
                                      color: "danger",
                                      position: "middle",
                                      icon: sadOutline,
                                    });
                                  } else {
                                    let getItem = { ...item };
                                    getItem.item_quantity = Number(
                                      e.detail.value
                                    );
                                    let setItem = getDiscItem(
                                      getItem,
                                      selectCustomer,
                                      selectTerm,
                                      setting
                                    );
                                    dispatch(CartAction.changeItem(setItem));
                                  }
                                }}
                              ></IonInput>
                              <IonButton
                                fill="clear"
                                color="light"
                                className="ion-no-padding ion-no-margin"
                                slot="end"
                                onClick={async () => {
                                  let getItem = { ...item };
                                  getItem.item_quantity =
                                    getItem.item_quantity + 1;
                                  let setItem = getDiscItem(
                                    getItem,
                                    selectCustomer,
                                    selectTerm,
                                    setting
                                  );
                                  dispatch(CartAction.changeItem(setItem));
                                }}
                              >
                                <IonIcon
                                  src={addCircleOutline}
                                  color={"danger"}
                                  size="large"
                                ></IonIcon>
                              </IonButton>
                            </IonItem>
                          </IonCol>
                        </IonRow>
                      </IonCard>
                    ))}
                    <IonItem className="ion-padding-top" lines="none"></IonItem>
                  </>
                ) : (
                  <>
                    <IonImg
                      class="ion-padding-end ion-margin-end"
                      src={"assets/icon/empty-cart.svg"}
                    />
                    <IonText className="ion-text-center" class="ion-padding">
                      <h4>
                        <strong>{t("cart") + " " + t("empty-title")}</strong>
                      </h4>
                    </IonText>
                  </>
                )}
              </IonCardContent>
            </IonCard>
            <IonFab slot="fixed" vertical="bottom" horizontal="end">
              <IonFabButton
                size="small"
                color={"danger"}
                data-desc={`${t("add")} ${t("item")}  `}
                onClick={() => setShowModalSelectOptionItem(true)}
              >
                <IonIcon icon={addCircleOutline} />
              </IonFabButton>
            </IonFab>
          </IonContent>
          <IonFooter>
            <IonItem>
              <IonText slot="start" color={"light"}>
                Total {t("price")} <br />
                <strong>
                  {" "}
                  {Rupiah(totalAmount)} ({totalQty})
                </strong>
              </IonText>
              <IonButton
                expand="block"
                color={"danger"}
                slot="end"
                type="submit"
                className="ion-no-padding"
                style={{ width: "100%" }}
                onClick={() => {
                  presentCheckout({
                    cssClass: "modal-fullscreen",
                  });
                }}
                disabled={
                  cart.length &&
                  selectCustomer.customer_id !== "-" &&
                  selectSalesman.salesman_id !== "-" &&
                  selectCustomerAddress.customer_address_id !== "-" &&
                  soFormId
                    ? false
                    : true
                }
              >
                {`${t("buy")} (${cart.length})`}
              </IonButton>
            </IonItem>
          </IonFooter>
          <SelectOption
            title={t("module.customer")}
            items={customer.map((value: any) => ({
              value: value.customer_id,
              text: `${value.customer_name} (${value.customer_id})`,
            }))}
            selectedItems={
              selectCustomer.customer_id !== "-"
                ? [selectCustomer.customer_id]
                : []
            }
            onSelectionCancel={() =>
              setShowModalSelectOptionCustomerId(
                !showModalSelectOptionCustomerId
              )
            }
            onSelectionChange={customerSelectionChanged}
            multiple={false}
            isOpen={showModalSelectOptionCustomerId}
          />
          <SelectOption
            title={t("term")}
            items={term.map((value: any) => ({
              value: value.term_id,
              text: `${value.term_name} TOP: ${value.term_days} ${t("day")}`,
            }))}
            selectedItems={
              selectTerm.term_id !== "-" ? [selectTerm.term_id] : []
            }
            onSelectionCancel={() =>
              setShowModalSelectOptionTerm(!showModalSelectOptionTerm)
            }
            onSelectionChange={termSelectionChanged}
            multiple={false}
            isOpen={showModalSelectOptionTerm}
          />
          <SelectOption
            title={t("salesman")}
            items={salesman.map((value: any) => ({
              value: value.salesman_id,
              text: `${value.salesman_name} (${value.salesman_id})`,
            }))}
            selectedItems={
              selectSalesman.salesman_id !== "-"
                ? [selectSalesman.salesman_id]
                : []
            }
            onSelectionCancel={() =>
              setShowModalSelectOptionSalesman(!showModalSelectOptionSalesman)
            }
            onSelectionChange={salesmanSelectionChanged}
            multiple={false}
            isOpen={showModalSelectOptionSalesman}
          />
          <SelectOption
            title={t("item")}
            items={item.map((value: any) => ({
              value: value.item_id,
              text: `${value.item_name} (${value.item_id})`,
            }))}
            selectedItems={[]}
            onSelectionCancel={() =>
              setShowModalSelectOptionItem(!showModalSelectOptionItem)
            }
            onSelectionChange={itemSelectionChanged}
            multiple={false}
            isOpen={showModalSelectOptionItem}
          />
          <SelectOption
            title={`${t("address")} ${t("module.customer")}`}
            items={customerAddress.map((value: any) => ({
              value: value.customer_address_id,
              text: `${value.customer_address_name} = ${value.customer_address}`,
            }))}
            selectedItems={
              selectCustomerAddress.customer_address_id !== "-"
                ? [selectCustomerAddress.customer_address_id]
                : []
            }
            onSelectionCancel={() =>
              setShowModalSelectOptionAddress(!showModalSelectOptionAddress)
            }
            onSelectionChange={addressSelectionChanged}
            multiple={false}
            isOpen={showModalSelectOptionAddress}
          />
          <InputDate
            onCloseModal={handleModalDateClose}
            initialData={soDate}
            isOpen={showModalDate}
          />
          <InputDate
            onCloseModal={handleModalDateExpectedClose}
            initialData={soDateExpected}
            isOpen={showModalDateExpected}
          />
        </>
      ) : (
        <IonList>
          <IonListHeader>
            <IonSkeletonText
              animated={true}
              style={{ width: "80px" }}
            ></IonSkeletonText>
          </IonListHeader>
          {loadList.map((value: any, key: any) => (
            <IonItem key={key}>
              <IonThumbnail slot="start">
                <IonSkeletonText animated={true}></IonSkeletonText>
              </IonThumbnail>
              <IonLabel>
                <h3>
                  <IonSkeletonText
                    animated={true}
                    style={{ width: "80%" }}
                  ></IonSkeletonText>
                </h3>
                <p>
                  <IonSkeletonText
                    animated={true}
                    style={{ width: "60%" }}
                  ></IonSkeletonText>
                </p>
                <p>
                  <IonSkeletonText
                    animated={true}
                    style={{ width: "30%" }}
                  ></IonSkeletonText>
                </p>
              </IonLabel>
            </IonItem>
          ))}
        </IonList>
      )}
    </IonPage>
  );
};

export default Form;
