import {
    IonContent,
    IonPage,
    IonSkeletonText,
    IonIcon,
    IonLabel,
    IonText,
    IonCard,
    IonItem,
    IonList,
    IonBadge,
    IonListHeader,
    IonThumbnail,
    IonImg,
    useIonAlert,
    IonCardContent,
    IonHeader,
    IonToolbar,
    IonButton,
    IonInput,
    IonInfiniteScroll, 
    IonInfiniteScrollContent,
    useIonModal,
    useIonToast,
    IonRefresher,
    IonRefresherContent,
    RefresherEventDetail
} from '@ionic/react';
import React, { useState } from 'react';
import { storefrontOutline, searchOutline, arrowBackOutline, warningOutline, chevronDownCircleOutline } from 'ionicons/icons';
import { RestApi } from './../services/Api';
import Detail from './../components/customer/Detail';
import { loadList } from '../models/Data';
import { useTranslation } from 'react-i18next';
import './Customer.scss';

const useConstructor = (callBack = () => {}) => {
  const [hasBeenCalled, setHasBeenCalled] = useState(false);
  if (hasBeenCalled) return;
  callBack();
  setHasBeenCalled(true);
}

const Customer: React.FC = () => {
  const [presentToast] = useIonToast();
  const { t } = useTranslation(["general"]);
  const [alert] = useIonAlert();
  const [isInfiniteDisabled, setInfiniteDisabled] = useState(false);
  const [getIdCustomer, setGetIdCustomer] = useState<any>([]);
  const [searchText, setSearchText] = useState<string>("");
  const [loaded, setLoaded] = useState<boolean>(false);
  const [listCustomer, setListCustomer] = useState<any>([]);
  const [dataCustomer, setDataCustomer] = useState<any>([]);

  const handleDismissDetail = (e: any) => {
    dismissDetail();
  };

  const [presentDetail, dismissDetail] = useIonModal(Detail, {
    idCustomer: getIdCustomer,
    onDismissDetail: handleDismissDetail,
  });

  const massageAlert = async (status: string, body: string) => {
    alert({
      header: t(status),
      message: body,
      cssClass: `header-alert-${status}`,
      buttons: [{ cssClass: "alert-button-confirm", text: "Ok" }],
    });
  };

  const getCustomerList = async (page: string, next: boolean) => {
    await RestApi(page, "get", {}, "application/json")
      .then((res: any) => {
        if (res.data.code === 200) {
          setDataCustomer(res.data.data);
          if (next) {
            setListCustomer((prevState: any) => [
              ...prevState,
              ...res.data.data.data,
            ]);
          } else {
            setListCustomer(res.data.data.data);
          }
        } else {
         setListCustomer([]);
         setDataCustomer([]);
         presentToast({
           message: res.data.message,
           duration: 2500,
           color: "danger",
           icon: warningOutline,
         });       
        }
        setLoaded(true);
      })
      .catch((err: any) => {
        massageAlert('error', err.message);
        setLoaded(true);
      });
  };

  const getCustomerDetail = async (id: string) => {
    setGetIdCustomer(id);
    presentDetail({
      cssClass: "modal-fullscreen",
    });
  };
  
  const loadData = (ev: any) => {
    if (dataCustomer.current_page === dataCustomer.last_page) {
      setInfiniteDisabled(true);
      return;
    }
    getCustomerList(
      `customer?page=${dataCustomer.current_page + 1}&search=${searchText}`,
      true
    );
    ev.target.complete();
  };

  const onChangeSearch = (e: any) => {
    setSearchText(e.detail.value!);
    getCustomerList(`customer?page=1&search=${e.detail.value!}`, false);
  };

  const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
    getCustomerList(`customer?page=1&search=${searchText}`, false);
    event.detail.complete();
  };

  useConstructor(() => {
    getCustomerList(`customer?page=1&search=${searchText}`, false);
  });

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className="toolbar-customer">
          <IonButton
            color="light"
            slot="start"
            fill="clear"
            routerLink="/home"
            routerDirection="back"
            className=""
            size="small"
          >
            <IonIcon color="light" src={arrowBackOutline} />
          </IonButton>
          <div className="search" slot="end">
            <IonItem lines="none" className="item-customer">
              <IonInput
                placeholder={t("search") + " " + t("module.customer")}
                className="input-customer"
                onIonInput={(e) => onChangeSearch(e)}
              ></IonInput>
              <IonIcon src={searchOutline} slot="start" />
              <IonBadge color="medium">{dataCustomer.total}</IonBadge>
            </IonItem>
          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent className="cust-content">
        {!loaded && (
          <IonList>
            <IonListHeader>
              <IonSkeletonText
                animated={true}
                style={{ width: "80px" }}
              ></IonSkeletonText>
            </IonListHeader>
            {loadList.map((value: any, key: any) => (
              <IonItem key={key}>
                <IonThumbnail slot="start">
                  <IonSkeletonText animated={true}></IonSkeletonText>
                </IonThumbnail>
                <IonLabel>
                  <h3>
                    <IonSkeletonText
                      animated={true}
                      style={{ width: "80%" }}
                    ></IonSkeletonText>
                  </h3>
                  <p>
                    <IonSkeletonText
                      animated={true}
                      style={{ width: "60%" }}
                    ></IonSkeletonText>
                  </p>
                  <p>
                    <IonSkeletonText
                      animated={true}
                      style={{ width: "30%" }}
                    ></IonSkeletonText>
                  </p>
                </IonLabel>
              </IonItem>
            ))}
          </IonList>
        )}
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent
            pullingIcon={chevronDownCircleOutline}
            pullingText={t("loading") ?? ""}
            refreshingSpinner="circles"
            refreshingText={t("loading") ?? ""}
          ></IonRefresherContent>
        </IonRefresher>
        {listCustomer.length && loaded ? (
          <IonList className="main-card">
            {listCustomer.map((value: any, key: any) => (
              <IonCard
                className="ion-padding-top"
                key={key}
                onClick={() => getCustomerDetail(value.customer_id)}
              >
                <IonItem className="ion-no-padding">
                  <IonIcon
                    size="small"
                    className="ion-padding-end"
                    src={storefrontOutline}
                  />
                  <IonText className="ion-no-padding">
                    <p>
                      <strong> {value.customer_name} </strong>
                    </p>
                  </IonText>
                  <IonBadge slot="end" color="dark">
                    {value.customer_id}
                  </IonBadge>
                </IonItem>
                <IonCardContent className="ion-no-padding">
                  <IonItem className="ion-no-padding" lines="none">
                    <IonLabel className="ion-no-padding">
                      <p>
                        {value.customer_address.length
                          ? value.customer_address[0]
                              .customer_address
                          : "-"}
                      </p>
                    </IonLabel>
                    <IonText slot="end" className="ion-no-padding">
                      <p>{value.customer_phone}</p>
                    </IonText>
                  </IonItem>
                </IonCardContent>
              </IonCard>
            ))}
          </IonList>
        ) : (
          <IonImg className="img-empty" src={"assets/img/search-empty.webp"} />
        )}
        <IonInfiniteScroll
          onIonInfinite={loadData}
          threshold="100px"
          disabled={isInfiniteDisabled}
        >
          {dataCustomer.next_page_url && (
            <IonInfiniteScrollContent
              loadingSpinner="bubbles"
              loadingText="Loading more data..."
            >
              <IonItem>
                <IonThumbnail slot="start">
                  <IonSkeletonText animated={true}></IonSkeletonText>
                </IonThumbnail>
                <IonLabel>
                  <h3>
                    <IonSkeletonText
                      animated={true}
                      style={{ width: "80%" }}
                    ></IonSkeletonText>
                  </h3>
                  <p>
                    <IonSkeletonText
                      animated={true}
                      style={{ width: "60%" }}
                    ></IonSkeletonText>
                  </p>
                  <p>
                    <IonSkeletonText
                      animated={true}
                      style={{ width: "30%" }}
                    ></IonSkeletonText>
                  </p>
                </IonLabel>
              </IonItem>
            </IonInfiniteScrollContent>
          )}
        </IonInfiniteScroll>
      </IonContent>
    </IonPage>
  );
};

export default Customer;
