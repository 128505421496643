import {
  IonHeader,
  IonToolbar,
  IonButton,
  IonCard,
  IonContent,
  IonItem,
  IonIcon,
  IonLabel,
  IonBadge,
  IonPage,
  IonText,
  IonFab, 
  IonFabButton, 
  IonList,
  IonImg,
  IonFabList,
  IonListHeader,
  IonSkeletonText,
  IonThumbnail,
  IonRefresher, 
  IonRefresherContent,
  useIonAlert,
  useIonModal
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { RefresherEventDetail } from '@ionic/core';
import { Preferences } from '@capacitor/preferences'
import { chevronDownCircleOutline, logIn, storefront, logOut, arrowBackOutline, menuSharp, locateOutline, mapOutline, cashOutline, walletOutline ,documentOutline, cartOutline, logInOutline, logOutOutline, chatboxEllipsesOutline, documentTextOutline, checkmarkCircleOutline, closeCircleOutline, addCircleOutline} from "ionicons/icons";
import { RestApi } from '../../services/Api';
import Map from '../../components/Map';
import History from './History';
import Ar from '../../components/customer/Ar';
import Payment from '../../components/customer/Payment';
import BackOrder from '../../components/customer/BackOrder';
import Sales from '../../components/customer/Sales';
import { useSelector, useDispatch } from "react-redux";
import { CustomerAction } from "../../data/state/CustomerReducer";
import { Visit } from '../../models/Modal';
import { useTranslation } from 'react-i18next';
import { getNameDate, getRoleMenu } from '../../services/Library';
import Form from '../so/Form';
import SoDetail from "../so/Detail";
import './Detail.scss';
import SelectOption from '../input/SelectOption';

const useConstructor = (callBack = () => {}) => {
  const [hasBeenCalled, setHasBeenCalled] = useState(false);
  if (hasBeenCalled) return;
  callBack();
  setHasBeenCalled(true);
}

const Detail: React.FC<Visit> = (props) =>{
  const [alert] = useIonAlert();
  const dispatch = useDispatch();
  const { t } = useTranslation(["general"]);
  const customer = useSelector((state:any) => state.customer.value);
  const [visit, setVisit] = useState<any>([]);
  const [visitOn, setVisitOn] = useState<any>();
  const [loaded, setLoaded] = useState<boolean>(false);
  const user = useSelector((state: any) => state.user.value);
  const getRoleSo = getRoleMenu(user.role_menu, "so");
  const [getIdSo, setGetIdSo] = useState<string>("");
  const [showModalSelectOptionAddress, setShowModalSelectOptionAddress] = useState<boolean>(false);
  const [address, setAddress] = useState<any>();

  const getVisitDetail = async (id:string,idCust:string) => {
      if (!id) {
        props.onDismissDetail()
        return;
      }
      const {value} =  await Preferences.get({key: "visitOn" });
      if (value) {
        setVisitOn(JSON.parse(value))
      }
      setLoaded(false)
      RestApi(`visit/detail-visit/${id}/${idCust}`,'get',{},'application/json')
      .then(async(res:any) => {
          if (res.data.code === 200) {
            setVisit(res.data.data)
            setLoaded(true)
          }else{
            props.onDismissDetail()
            massageAlert('error',res.data.message)
          }
      })
      .catch((err:any) => {
          props.onDismissDetail()
          massageAlert('error',err.message)
      });
  }

  const massageAlert = async (status:string,body:string) => {
    alert({
      header: t(status),
      message: body,
      cssClass: `header-alert-${status}`,
      buttons: [{ text: 'Ok', handler: (d:any) => {} }],
    })
  }

  const checkStatus = (status:any) =>{
    switch(status) {
        case 0:
        return {
            color : 'danger',
            icon : logIn
        };
      case 1:
        return {
            color : 'success',
            icon : logOut
        };
      default:
        return {
            color : 'tertiary',
            icon : storefront
        };
    }
  }

  const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
    getVisitDetail(customer.visit_id,customer.customer_id)
    event.detail.complete();
  }

  const handleDismissMap = (e:any) => {
    getVisitDetail(props.detailVisit.visit_id,props.detailVisit.customer_id)
    dismissMap()
  }

  const handleDismissHistory = (e:any) => {
    dismissHistory()
  }

  const handleDismissAr = (e:any) => {
    dismissAr()
  }

  const handleDismissPayment = (e:any) => {
    dismissPayment()
  }

  const handleDismissBackOrder = (e:any) => {
    dismissBackOrder()
  }

  const handleDismissSales = (e:any) => {
    dismissSales()
  }

  const handleDismissForm = () => {
    getVisitDetail(props.detailVisit.visit_id, props.detailVisit.customer_id);
    dismissForm();
  };

  const handleDismissDetail = (e: any) => {
    dismissDetail();
  };


  const [presentDetail, dismissDetail] = useIonModal(SoDetail, {
    idSo: getIdSo,
    onDismiss: handleDismissDetail,
  });

  const [presentForm, dismissForm] = useIonModal(Form, {
    idSo: "",
    idVisit: props.detailVisit.visit_id,
    idCustomer: customer.customer_id,
    type: "create",
    onDismiss: handleDismissForm,
  });

  const [presentMap, dismissMap] = useIonModal(Map, {
    customer: customer,
    type: "visit",
    visit: visit,
    address: address,
    onDismissDetail: handleDismissMap,
  });

  const [presentHistory, dismissHistory] = useIonModal(History, {
    idCustomer: customer.customer_id,
    onDismissDetail: handleDismissHistory
  });

  const [presentAr, dismissAr] = useIonModal(Ar, {
    idCustomer: customer.customer_id,
    onDismissDetail: handleDismissAr
  });

  const [presentPayment, dismissPayment] = useIonModal(Payment, {
    idCustomer: customer.customer_id,
    onDismissDetail: handleDismissPayment
  });

  const [presentBackOrder, dismissBackOrder] = useIonModal(BackOrder, {
    idCustomer: customer.customer_id,
    onDismissDetail: handleDismissBackOrder
  });
  
  const [presentSales, dismissSales] = useIonModal(Sales, {
      idCustomer: customer.customer_id,
      onDismissDetail: handleDismissSales
  });

  const getSoDetail = async (id: string) => {
    setGetIdSo(id);
    presentDetail({
      cssClass: "modal-fullscreen",
    });
  };

  const dateToHour = (arr:any,status:number) =>{
    let dateCheck = arr.filter((value:any) => value.visit_hist_type === status );
    if (dateCheck.length){
      let date = new Date(dateCheck[status === 0 ? 0 : dateCheck.length - 1 ].visit_hist_at);
      if (date instanceof Date) {
        return (
          date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()
        );
      }else{
        return '-';
      }
    }else{
      return '-'
    }
  }

  const returnElapsedTime = (epoch:number) => {
    let hours = epoch / 3600, minutes = (hours % 1) * 60, seconds = (minutes % 1) * 60;
    return (
      (Math.floor(hours) ? Math.floor(hours) + " Jam " : "") +
      (Math.floor(minutes) ? Math.floor(minutes) + " Menit " : "") +
      Math.round(seconds) + " Detik"
    );
  }

  const checkMap = () => {
    if (visitOn && visitOn.customer_id !== customer.customer_id) {
      alert({
        header: `Customer ${visitOn.customer_id} Belum Di Proses !`,
        message: `Sekarang Atau nanti?`,
        cssClass: 'header-alert',
        buttons: [
          {
                text: 'Nanti',
                cssClass: 'alert-button-cancel',
                role: 'cancel',
                handler:  () => {

                },
            },
            {
                text: 'Sekarang',
                cssClass: 'alert-button-confirm',
                role: 'confirm',
                handler:  () => {
                  dispatch(CustomerAction.setCustomer(visitOn))
                  getVisitDetail(visitOn.visit_id,visitOn.customer_id)
            },
        }]
      })
    }else{
      presentMap({
        cssClass: "modal-fullscreen",
      });
    }
  }

  const addressSelectionChanged = (idAddress: string[]) => {
    setAddress(
      visit.customer_address.find(
        (val: any) => val.customer_address_id === idAddress
      )
    );
    presentMap({
      cssClass: "modal-fullscreen",
    });
    return;
  };
  
  useConstructor(() => {
    getVisitDetail(props.detailVisit.visit_id,props.detailVisit.customer_id)
  });

  useEffect(() => {

  },[visit,customer]);

  return (
    <IonPage className="detail-content">
      <IonHeader>
        <IonToolbar class="toolbar-detail-visit ion-no-padding">
          <IonButton
            color="light"
            slot="start"
            fill="clear"
            onClick={() => props.onDismissDetail()}
          >
            <IonIcon color="light" slot="start" src={arrowBackOutline} />
          </IonButton>
          <IonItem className="ion-no-padding">
            <IonLabel className="ion-no-padding" color="light">
              <h4>
                <strong>
                  {customer.customer_name} ({customer.customer_title})
                </strong>
              </h4>
              <IonBadge className="ion-no-padding" color="dark">
                {customer.customer_id}
              </IonBadge>
            </IonLabel>
            <IonIcon
              slot="end"
              size="medium"
              color={checkStatus(customer.visit_hist_type).color}
              src={checkStatus(customer.visit_hist_type).icon}
            />
          </IonItem>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent
            pullingIcon={chevronDownCircleOutline}
            pullingText={`${t("loading")}`}
            refreshingSpinner="circles"
            refreshingText={`${t("loading")}`}
          ></IonRefresherContent>
        </IonRefresher>
        <IonCard className="ion-no-padding">
          <IonItem>
            <IonText className="ion-no-padding">
              <span className="type">
                {" "}
                <strong>{customer.customer_type_name}</strong>
              </span>
            </IonText>
            <IonBadge slot="end" color="dark">
              {customer.area_name} - {customer.subarea_name}
            </IonBadge>
          </IonItem>
          <IonItem>
            <IonText className="ion-no-padding">
              <span>
                Nama : <strong>{customer.customer_contact_name}</strong>
              </span>
              <span>
                Jabatan : <strong>{customer.customer_contact_title}</strong>
              </span>
              <span>
                Handphone : <strong>{customer.customer_phone}</strong>
              </span>
              <span>
                Kota : <strong>{customer.customer_city}</strong>
              </span>
              <span>
                Provinsi : <strong>{customer.customer_state}</strong>
              </span>
              <span>
                Alamat : <strong>{customer.customer_address}</strong>
              </span>
            </IonText>
          </IonItem>
        </IonCard>
        {!loaded && (
          <IonList>
            <IonListHeader>
              <IonSkeletonText
                animated={true}
                style={{ width: "80px" }}
              ></IonSkeletonText>
            </IonListHeader>
            <IonItem>
              <IonThumbnail slot="start">
                <IonSkeletonText animated={true}></IonSkeletonText>
              </IonThumbnail>
              <IonLabel>
                <h3>
                  <IonSkeletonText
                    animated={true}
                    style={{ width: "80%" }}
                  ></IonSkeletonText>
                </h3>
                <p>
                  <IonSkeletonText
                    animated={true}
                    style={{ width: "60%" }}
                  ></IonSkeletonText>
                </p>
                <p>
                  <IonSkeletonText
                    animated={true}
                    style={{ width: "30%" }}
                  ></IonSkeletonText>
                </p>
              </IonLabel>
            </IonItem>
          </IonList>
        )}

        {loaded ? (
          <>
            <IonCard className="ion-no-padding">
              <IonItem>
                <IonText className="ion-no-padding ion-no-margin">
                  <h4>
                    <strong>Keterangan &nbsp;</strong>
                    <IonIcon color="primary" icon={documentTextOutline} />
                  </h4>
                  <p>{visit.visitdet_remark ?? "-"}</p>
                </IonText>
              </IonItem>
              <IonItem>
                <IonText className="ion-no-padding ion-no-margin">
                  <h4>
                    <strong>Check In &nbsp;</strong>
                  </h4>
                </IonText>
                <IonIcon slot="end" color="danger" icon={logInOutline} />
                <IonText slot="end">{dateToHour(visit.visit_hist, 0)}</IonText>
              </IonItem>
              <IonItem>
                <IonText className="ion-no-padding ion-no-margin">
                  <h4>
                    <strong>Catatan &nbsp;</strong>
                    <IonIcon
                      color="primary"
                      icon={chatboxEllipsesOutline}
                    />{" "}
                  </h4>
                  <IonText>{visit.visitdet_note}</IonText>
                </IonText>
              </IonItem>
              <IonItem>
                <IonText className="ion-no-padding ion-no-margin">
                  <h4>
                    <strong>Check Out</strong>
                  </h4>
                </IonText>
                <IonIcon slot="end" color="success" icon={logOutOutline} />
                <IonText slot="end">{dateToHour(visit.visit_hist, 1)}</IonText>
              </IonItem>
              <IonCard className="ion-padding">
                <IonText className="ion-no-padding ion-no-margin ion-text-center">
                  <h4 className="ion-no-padding ion-no-margin ion-text-center">
                    <strong>WAKTU KUNJUNGAN</strong>
                  </h4>
                </IonText>
                <IonButton expand="block">
                  {returnElapsedTime(visit.visitdet_duration)}
                </IonButton>
              </IonCard>
              <IonItem
                onClick={() => {
                  if (visit.visitdet_so_id) {
                    getSoDetail(visit.visitdet_so_id);
                  }
                }}
              >
                <IonText className="ion-no-padding ion-no-margin">
                  <h4>
                    <strong>
                      Ada {t("module.so")} ? &nbsp;
                      <IonIcon
                        color={visit.visitdet_so_id ? "success" : "dangert"}
                        src={
                          visit.visitdet_so_id
                            ? checkmarkCircleOutline
                            : closeCircleOutline
                        }
                      />
                    </strong>
                  </h4>
                </IonText>

                <IonText slot="end" className="ion-text-right">
                  <span>{visit.visitdet_so_id}</span>
                </IonText>
              </IonItem>
              <IonItem>
                <IonText className="ion-no-padding ion-no-margin">
                  <h4>
                    <strong>
                      Check By &nbsp;
                      <IonIcon
                        color={visit.visitdet_is_visit ? "success" : "dangert"}
                        src={
                          visit.visitdet_is_visit
                            ? checkmarkCircleOutline
                            : closeCircleOutline
                        }
                      />
                    </strong>
                  </h4>
                </IonText>

                <IonText slot="end" className="ion-text-right">
                  <span>{visit.visit_check_by}</span>
                  <span>
                    {visit.visit_check_at
                      ? getNameDate(visit.visit_check_at, true)
                      : "-"}
                  </span>
                </IonText>
              </IonItem>
            </IonCard>
            <SelectOption
              title={`${t("address")} ${t("module.customer")}`}
              items={visit.customer_address.map((value: any) => ({
                value: value.customer_address_id,
                text: `${value.customer_address_name} - ${value.customer_address}`,
              }))}
              selectedItems={[]}
              onSelectionCancel={() =>
                setShowModalSelectOptionAddress(!showModalSelectOptionAddress)
              }
              onSelectionChange={addressSelectionChanged}
              multiple={false}
              isOpen={showModalSelectOptionAddress}
            />
          </>
        ) : (
          <IonImg className="img-empty" src={"assets/img/search-empty.webp"} />
        )}
      </IonContent>
      {loaded && (
        <IonFab horizontal="end" vertical="bottom">
          <IonFabButton className="fab-up" size="small">
            <IonIcon size="small" icon={menuSharp} />
          </IonFabButton>
          <IonFabList side="top">
            {props.type === "visit" && (
              <IonFabButton
                data-desc="Lokasi"
                onClick={() => {
                  if (visit.visit_hist[0]?.visit_hist_customer_address_id) {
                    setAddress(
                      visit.customer_address.find(
                        (val: any) =>
                          val.customer_address_id ===
                          visit.visit_hist[0].visit_hist_customer_address_id
                      )
                    );
                    checkMap();
                  } else {
                    setShowModalSelectOptionAddress(true);
                  }
                }}
              >
                <IonIcon icon={locateOutline} />
              </IonFabButton>
            )}
            <IonFabButton
              data-desc="History Visit"
              onClick={() => {
                presentHistory({
                  cssClass: "modal-fullscreen",
                });
              }}
            >
              <IonIcon size="medium" icon={mapOutline} />
            </IonFabButton>
            <IonFabButton
              data-desc="Daftar Piutang"
              onClick={() => {
                presentAr({
                  cssClass: "modal-fullscreen",
                });
              }}
            >
              <IonIcon icon={documentOutline} />
            </IonFabButton>
            <IonFabButton
              data-desc="Back Order"
              onClick={() => {
                presentBackOrder({
                  cssClass: "modal-fullscreen",
                });
              }}
            >
              <IonIcon size="medium" icon={cartOutline} />
            </IonFabButton>
            <IonFabButton
              data-desc="Pembayaran"
              onClick={() => {
                presentPayment({
                  cssClass: "modal-fullscreen",
                });
              }}
            >
              <IonIcon size="medium" icon={cashOutline} />
            </IonFabButton>
            <IonFabButton
              data-desc={`${t("module.so")}`}
              onClick={() => {
                presentSales({
                  cssClass: "modal-fullscreen",
                });
              }}
            >
              <IonIcon size="medium" icon={walletOutline} />
            </IonFabButton>
            {getRoleSo?.role_menu_add === 1 &&
              !visit.visitdet_so_id &&
              [0, 1].includes(customer.visit_hist_type) && (
                <IonFabButton
                  data-desc={`${t("create")} ${t("module.so")}`}
                  onClick={() => {
                    presentForm({
                      cssClass: "modal-fullscreen",
                    });
                  }}
                >
                  <IonIcon icon={addCircleOutline} />
                </IonFabButton>
              )}
          </IonFabList>
        </IonFab>
      )}
    </IonPage>
  );
};

export default Detail;
